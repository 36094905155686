// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-baixar-taxsheets-js": () => import("./../../../src/pages/baixar-taxsheets.js" /* webpackChunkName: "component---src-pages-baixar-taxsheets-js" */),
  "component---src-pages-campos-registros-efd-icms-js": () => import("./../../../src/pages/campos-registros-efd-icms.js" /* webpackChunkName: "component---src-pages-campos-registros-efd-icms-js" */),
  "component---src-pages-cclass-cst-ibs-cbs-js": () => import("./../../../src/pages/cclass-cst-ibs-cbs.js" /* webpackChunkName: "component---src-pages-cclass-cst-ibs-cbs-js" */),
  "component---src-pages-comprar-js": () => import("./../../../src/pages/comprar.js" /* webpackChunkName: "component---src-pages-comprar-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-demonstracao-js": () => import("./../../../src/pages/demonstracao.js" /* webpackChunkName: "component---src-pages-demonstracao-js" */),
  "component---src-pages-erros-pva-efd-contribuicoes-js": () => import("./../../../src/pages/erros-pva-efd-contribuicoes.js" /* webpackChunkName: "component---src-pages-erros-pva-efd-contribuicoes-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manutencao-js": () => import("./../../../src/pages/manutencao.js" /* webpackChunkName: "component---src-pages-manutencao-js" */),
  "component---src-pages-planos-js": () => import("./../../../src/pages/planos.js" /* webpackChunkName: "component---src-pages-planos-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-politica-de-seguranca-da-informacao-js": () => import("./../../../src/pages/politica-de-seguranca-da-informacao.js" /* webpackChunkName: "component---src-pages-politica-de-seguranca-da-informacao-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-taxdashs-js": () => import("./../../../src/pages/taxdashs.js" /* webpackChunkName: "component---src-pages-taxdashs-js" */),
  "component---src-pages-taxsheets-js": () => import("./../../../src/pages/taxsheets.js" /* webpackChunkName: "component---src-pages-taxsheets-js" */),
  "component---src-pages-termos-de-uso-js": () => import("./../../../src/pages/termos-de-uso.js" /* webpackChunkName: "component---src-pages-termos-de-uso-js" */)
}

